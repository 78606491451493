import { useLocation } from "@reach/router"
import CarouselHeader from "components/CarouselHeader"
import ProductUnavailable from "components/pages/Shop/ProductUnavailable"
import ScrollTopButton from "components/ScrollTopButton"
import ShopHeader from "components/ShopHeader"
import ProductContentDynamicZone from "components/Strapi/ProductContentDynamicZone"
import ProductHero from "components/Strapi/ProductHero"
import ProductReviews from "components/Strapi/ProductReviews"
import ShopTiles, { formatStrapiTile } from "components/Strapi/ShopTiles"
import { graphql, navigate } from "gatsby"
import { Button } from 'nzk-react-components'
import React, { useMemo, useState } from "react"
// @ts-ignore
import { useShoppingCart } from "use-shopping-cart"
import useHubspotTracking from "../../../hooks/useHubspotTracking"
import useScroll from "../../../hooks/useScroll"
import { formatProductForCountry } from "../../../hooks/useStrapiShopProduct"
import Layout from "../../../layouts/ShopLayout"
import { useShopLocaleState } from "../../../providers/ShopLocaleProvider"
import CheckoutModal from "../CheckoutModal"
import * as s from "../index.styles"

interface IProps {
  data: {
    site: {
      siteMetadata: {
        siteUrl
      }
    }
    allStrapiCountries: {
      edges: {
        node: Strapi.Country
      }[]
    }
    strapiShopProducts: Strapi.ShopProduct
  }
}

const getSkuForLocale = (product, locale) => {
  const skus = product.skus
  .map(s => ({
    ...s,
    pricings: s.pricings?.filter(
      p => p.countries.map(l => l.locale).indexOf(locale) >= 0
    ),
  }))
  .filter(s => s.pricings?.length > 0)
  return skus[0]
}

const getPricingForLocale = (sku, locale) => {
  const pricings =
    sku?.pricings?.filter(
      p => p.countries.map(l => l.locale).indexOf(locale) >= 0
    ) || []
  return pricings[0]
}

const ShopProductTemplate = (props: IProps) => {
  const {
    data: { strapiShopProducts: shopProduct, allStrapiCountries },
  } = props
  const location = useLocation()
  const { addItem, cartDetails, incrementItem, decrementItem } =
    useShoppingCart()
  const { scrollToElement } = useScroll()
  const { locale } = useShopLocaleState()
  const { trackCustomEvent } = useHubspotTracking()
  const [showCheckoutModal, setShowCheckoutModal] = useState(false)

  const { node: country } = allStrapiCountries.edges.find(
    ({ node }) => node.locale === locale
  ) as { node: Strapi.Country }
  const product = country
    ? formatProductForCountry(shopProduct, country)
    : shopProduct

  const sku = getSkuForLocale(product, locale)
  const pricing = getPricingForLocale(sku, locale)

  const onPurchaseAsGift = () => {
    navigate('/shop/products/personalized-book-gift-voucher')
  }

  const onAddItem = () => {
    if (Object.keys(cartDetails).length === 0) {
      trackCustomEvent("pe19679358_created_basket", {})
    }
    addItem(
      {
        id: sku.sku,
        sku: sku.sku,
        price: pricing?.amount,
        currency: pricing?.currency.toUpperCase(),
        name: product.name,
        description: sku.shortDescription,
        image: sku.thumbnail?.url || product.thumbnail?.url,
        metadata: {
          warehouse: sku.warehouse,
          countryCodes: pricing?.countries?.map(c => c.code),
          shippingRate: pricing?.shippingRate,
          shopShippingRate: sku.pricings[0].shopShippingRate,
          digital: product.digital,
        },
      },
      { count: 1 }
    )

    const params = new URLSearchParams(location.search)
    const isAd = params.get("checkout-modal")
    if (isAd === "true") {
      setShowCheckoutModal(true)
    }
  }

  const content = useMemo(
    () => (
      <ProductContentDynamicZone
        content={product.content}
        actionBinds={{
          ADD_TO_BASKET: () => {
            onAddItem()
            setShowCheckoutModal(true)
            scrollToElement("#product-hero")
          },
        }}
      />
    ),
    [sku]
  )

  const slides =
    shopProduct.CarouselHero?.CarouselHeroElements.map(element => ({
      id: element.id,
      backgroundImage: element.backgroundImage,
      image: element.image,
      title: element.title,
      button: element.button && {
        label: element.button.value,
        target: element.button.targetUrl,
        external: element.button.targetUrl[0] !== "/",
      },
      BulletItems: element.BulletItems,
      richContent: element.richContent,
    })) || []

  const carousel = useMemo(() => {
    if (slides.length > 0) return <CarouselHeader slides={slides} />
    return null
  }, [])

  if (!pricing) {
    return (
      <s.Wrapper style={{ paddingTop: carousel ? "0px" : "50px" }}>
        {carousel}
        <ShopHeader />
        <ProductUnavailable product={product} />
      </s.Wrapper>
    )
  }

  return (
    <>
      {showCheckoutModal && (
        <CheckoutModal dismiss={() => setShowCheckoutModal(false)} />
      )}
      <s.Wrapper style={{ paddingTop: carousel ? "0px" : "50px" }}>
        {carousel}
        <ShopHeader />
        <ProductHero
          product={product}
          onAddItem={onAddItem}
          extraButtons={[
            <Button key='gift' theme='orange' size='small' onClick={onPurchaseAsGift}>
              Buy Gift Voucher
            </Button>
          ]}
          quantity={cartDetails[sku.sku]?.quantity || 0}
          incrementItem={() => incrementItem(sku.sku, { count: 1 })}
          decrementItem={() => decrementItem(sku.sku, { count: 1 })}
        />
        {content}
        {product.ProductReviews && (
          <ProductReviews productReviews={product.ProductReviews} />
        )}
        {product.tiles && (
          <ShopTiles tiles={product.tiles.map(s => formatStrapiTile(s))} />
        )}
      </s.Wrapper>
      <ScrollTopButton />
    </>
  )
}

export const query = graphql`
  query ($id: Int) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allStrapiCountries {
      edges {
        node {
          locale
          name
          currencyCode
          conversionRateToUSD
        }
      }
    }
    strapiShopProducts(strapiId: { eq: $id }) {
      id
      name
      description
      CarouselHero {
        ...CarouselHero
      }
      tiles {
        ...ShopTile
      }
      digital
      productBullets {
        value
      }
      images {
        id
        url
        mime
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: BLURRED)
          }
        }
        alternativeText
      }
      fullWidthImages: images {
        id
        url
        mime
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        alternativeText
      }
      thumbnail {
        id
        url
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: BLURRED, quality: 90)
          }
        }
        alternativeText
      }
      fullWidthThumbnail: thumbnail {
        id
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 90
            )
          }
        }
        alternativeText
      }
      content {
        ...RichSection
        ...ProductQuote
        ...ProductHighlight
        ...ProductVideo
        ...ProductCTA
        __typename
      }
      ProductReviews {
        title
        reviewsUrl
        reviewsServiceImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(height: 54, placeholder: BLURRED, quality: 90)
            }
          }
        }
        nbReviews
        rating
        reviews {
          id
          name
          rating
          text
        }
      }
      addToBasketButton {
        value
        theme
        targetUrl
      }
      skus {
        id
        title
        sku
        description
        shortDescription
        warehouse
        pricings {
          countries {
            locale
            code
          }
          shippingRate
          shopShippingRate {
            ...ShippingRate
          }
          currency
          amount
          discountedPercent
          originalAmount
        }
        thumbnail {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, placeholder: BLURRED)
            }
          }
          alternativeText
        }
        fullWidthThumbnail: thumbnail {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 90
              )
            }
          }
          alternativeText
        }
        images {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, placeholder: BLURRED)
            }
          }
          alternativeText
        }
        fullWidthImages: images {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 90
              )
            }
          }
          alternativeText
        }
      }
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
    }
  }
`

export default (props: IProps) => {
  const {
    data: { strapiShopProducts },
  } = props

  const getDefaultSEO = (product: Strapi.ShopProduct) => ({
    description:
      product.SEO?.description || `${product.description?.slice(0, 140)}...`,
    image: product.SEO?.image?.url || product.thumbnail?.url,
    title: product.SEO?.title || product.name,
    noIndex: product.SEO?.noIndex || false,
    url:
      product.SEO?.url ||
      `${props.data.site.siteMetadata.siteUrl}/shop/products/${product.slug}`,
    canonical: product.SEO?.canonical || null,
  })

  return (
    <Layout seoProps={getDefaultSEO(strapiShopProducts)}>
      <ShopProductTemplate {...props} />
    </Layout>
  )
}
